.slider {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  position: relative;
}

.images-container {
  display: flex;
  overflow: scroll;
  scroll-behavior: smooth;
  transition: scroll 0.3s ease-in-out;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar:horizontal {
  height: 0;
  width: 0;
  display: none;
}

::-webkit-scrollbar-thumb:horizontal {
  display: none;
}

.slider .product {
  box-sizing: border-box;
  width: 200px;
  border: 1px solid transparent;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slider .category {
  box-sizing: border-box;
  max-width: 150px;
  border: 1px solid transparent;
  margin: 0 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slider .product:hover {
  border: 1px solid #eee;
}

.slider .product .image-container {
  padding: 5px;
  width: 60px;
  height: 60px;
  border-radius: 8px;
}

.slider .category .image-container {
  padding: 5px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.slider .category {
  border-bottom: 3px solid transparent;
}

.slider .category .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.slider .category:hover {
  border-bottom: 3px solid #c3c3c3;
}
.slider .category.active {
  border-bottom: 3px solid #000;
}

.nav-btn {
  // position: absolute;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  background-color: #fff;
  border: 1px solid #c3c3c3;
  border-radius: 50%;
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.nav-btn.left {
  left: 0;
}
.nav-btn.right {
  right: 0;
}
