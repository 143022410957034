.product {
  width: 100%;
  position: relative;
  text-decoration: none;
  margin-bottom: 40px;
  padding-left: 12px;
  padding-right: 12px;
}

.product .product-image {
  width: 100%;
  height: 250px;
  position: relative;
  margin-bottom: 12px;
}

.product .product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
}

.product .product-title,
.product .product-subtitle,
.product .product-price {
  font-size: 17px;
  margin-bottom: 2px;
}

.product .product-title {
  color: #666565;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.product .product-subtitle {
  font-weight: 400;
  color: #b0b0b0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.product .product-price {
  margin-top: 6px;
  color: #000;
  font-size: 20px;
}

.product .product-action {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
