.single-product .product-category {
  font-weight: 400;
  color: #b0b0b0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.single-product .product-title {
  font-weight: bold;
  color: #000;
}

.single-product .product-price {
  margin-bottom: 14px;
}

.single-product .product-description {
  margin-top: 16px;
}
