.nav-link {
  margin-left: 1rem;
  margin-right: 1rem;
}

.nav-item {
  color: #000 !important;
}

.logo {
  font-family: Agbalumo;
  font-size: 30px;
  color: $primary;
}

.search-wrapper {
  border: 2px solid $primary;
  border-radius: 5px;
}
