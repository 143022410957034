footer {
  p,
  i {
    color: rgba(162, 160, 160, 0.85);
    font-size: 1.5rem;
  }
  .logo {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 1em;
  }
  a {
    text-decoration: none;
    color: rgba(11, 11, 11, 0.85);
    transition: color ease-in 0.3s;
    font-size: 1rem;

    &:hover {
      text-decoration: underline;
      color: $primary;
    }
  }
}
.footer-heading {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.25em;
  opacity: 0.5;
  color: $black;
  margin-bottom: 1.5rem;
}

.footer-nav {
  list-style: none;
  padding-left: 0;
  li {
    margin-bottom: 0.35rem;
  }

  a {
    text-decoration: none;
    color: rgba(11, 11, 11, 0.85);
    transition: color ease-in 0.3s;
    font-size: 1rem;

    &:hover {
      text-decoration: underline;
      color: $primary;
    }
  }
}

.app-store-btn {
  width: 150px;
  height: 50px;
  border-radius: 2px;
}
.app-store-btn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.play-store-btn {
  width: 150px;
  height: 50px;
  border-radius: 2px;
}
.play-store-btn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.secured-payment {
  width: 200px;
  height: 50px;
  border-radius: 2px;
}
