.gallery .image-principal {
  width: 100%;
  border: 1px solid #ddd;
  margin-bottom: 12px;
  object-fit: contain;
}

.gallery .image-principal img {
  width: 100%;
}

.image-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.image-list .img-container {
  border: 1px solid #ddd;
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.image-list .img-container:hover {
  border: 1px solid red;
}

.image-list .img-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
