@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Agbalumo&family=Rubik:wght@300;400;500;700;900&display=swap");

@import "../../node_modules/bootstrap-icons/font/bootstrap-icons";
@import "../../node_modules/bootstrap/scss/bootstrap";

@import "../styles/base/base";
@import "../styles/components/components";

.navbar .dropdown-toggle::after {
  display: none !important;
}
